* {
  font-family: 'Exo 2', sans-serif !important;
}

.container {
  display: flex;
  flex-direction: column;

  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.navigation {
  position: fixed;
  top: 21px;
  left: 16px;

  display: flex;
  align-items: center;
  padding: 10px;
  gap: 8px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.navButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  background-color: transparent;
  outline: none;
  border: none;
}

.navButton img {
  width: 16px;
  height: 16px;
}

.radioWrapper {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 700;
  color: #2aacf4;
  background-color: #e2f4ff;

  width: 40px;
  height: 40px;
  border-radius: 50%;

  cursor: pointer;

  transition: all 0.3s ease;
}

.radioWrapper.unanswered {
  background-color: #f0f0f0;
  color: #9e9e9e;
  cursor: default;
}

.radioWrapper:hover,
.radioWrapper.active {
  background-color: #1890ff;
  border-color: #1890ff;
  color: #fff;
}

.status {
  position: absolute;
  bottom: 1px;
  right: 1px;

  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #d9d9d9;
}

.status.unanswered {
  background-color: #f0f0f0;
}

.status.correct {
  background-color: #6fef83;
}

.status.incorrect {
  background-color: #ff7278;
}

.radio {
  display: none;
}

.content {
  padding: 16px;
  height: 100%;

  display: flex;
  gap: 24px;
}

.questionWrapper {
  width: 100%;
  padding: 10px;

  overflow-y: auto;
  height: fit-content;
  max-height: 100%;

  display: flex;
  flex-direction: column;
  gap: 8px;

  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.textFromNPA {
  font-style: italic;
}

.questionWrapper h3 {
  margin: 0;
  font-size: 1.5rem;
}

.question {
  display: flex;
  flex-direction: column;
}

.questionTitle {
  font-size: 1.3rem;
}

.questionWrapper .question .pic {
  padding-top: 12px;
  max-width: 20%;
  height: auto;

  margin-top: 10px;
}

.answersWrapper {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 8px;
}

.answer {
  width: 100%;
  padding: 16px;

  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  display: flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;
  transition: 0.3s;
}

.activeAnswer {
  background-color: #2aacf4;
  color: #fff;
}

.answerId {
  width: 37px;
  height: 37px;
  border-radius: 50%;

  background-color: #e1f3ff;
  color: #2aacf4;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.875rem;
  line-height: 1.5rem;

  font-weight: bold;
  flex-shrink: 0;
}

.answerContent {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.correctBorder {
  border: 3px solid #6fef83;
  color: #00c52b;
}

.correct {
  color: #00c52b;
}

.incorrectBorder {
  border: 3px solid #ff7278;
}

.incorrect {
  color: #ff7278;
}

.answerContent .pic {
  padding-top: 12px;
  max-width: 20%;

  margin-top: 10px;
}

.actions {
  margin: 0 16px;
  margin-top: auto;
  height: fit-content;

  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
}

.actions .buttons {
  display: flex;
  gap: 8px;
}

.actions .buttons .actionButton {
  border: none;
  border-radius: 8px;
  background-color: #2aacf4;

  color: var(--white);
  display: flex;
  align-items: center;
  padding: 30px 35px;

  font-size: 1.3rem;
  line-height: 1.5rem;
  font-weight: 600;
}

.actions .buttons .actionButtonPrev {
  background: transparent;
  border: 1px solid var(--gray);
  color: var(--gray);
}

.actions .buttons .actionButton:hover {
  background-color: #e2f4ff;
  color: #2aacf4;
}

.actionButtonPrev:disabled {
  background-color: #f5f5f5 !important;
  opacity: 0.6;
}

.result {
  display: flex;
  align-items: center;
  gap: 20px;
}

.countQuestions {
  display: flex;
  align-items: center;

  padding: 12px 20px;

  color: #55b9f6;
  background-color: #e1f3ff;

  font-weight: 500;
  font-size: 1.5rem;
  border-radius: 8px;

  height: fit-content;
}

.statusAnswerText {
  font-family: var(--font-family-notosans);
  font-style: normal;
  font-size: 1.5rem;
}

.correctAnswerText {
  color: #00c52b;
}

.incorrectAnswerText {
  color: #ff7278;
}

@media screen and (max-width: 1024px) {
  .actions {
    flex-direction: column;
    gap: 16px;

    align-items: center;
    justify-content: center;
  }

  .statusAnswerText {
    font-size: 1.3rem;
  }

  .actions .countQuestions {
    position: fixed;

    top: 18.75px;
    left: 16px;

    padding: 12px 20px;
    font-size: 1.3rem;
  }

  .actions .buttons .actionButton {
    padding: 24px 8px;

    font-size: 0.875rem;
    font-weight: 500;
  }

  .questionWrapper {
    overflow: hidden;
    min-height: fit-content;
  }

  .questionWrapper h3 {
    font-size: 1rem;
  }

  .questionTitle,
  .textFromNPA {
    font-size: 0.875rem;
  }

  .content {
    flex-direction: column;
    overflow-y: auto;
  }

  .navigation {
    display: none;
  }
}

@media screen and (max-width: 370px) {
  .actions .buttons .actionButton {
    padding: 24px 8px;

    font-size: 0.8rem;
  }
}
